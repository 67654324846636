<template>
    <div>
        <!-- <add-file v-if="checkPermission('doublemajor_uploaddocument') && statusOfDocuments" :documents="documentsData"/> -->
        <ValidationObserver ref="formModalValidate" v-if="preferenceProcessesShowStatus">
            <b-row class="mb-4">
                <b-col cols="12" lg="9">
                    <div class="font-weight-medium mb-1">{{ $t('faculty') + ' / ' + $t('program') }}</div>
                    <div class="mb-1">
                        <ValidationProvider name="faculty_code" v-slot="{valid, errors}">
                            <faculty-selectbox v-model="form.faculty_code"
                                               :validate-error="errors[0]"
                            />
                        </ValidationProvider>
                    </div>
                    <div class="mb-2">
                        <ValidationProvider name="program_code" v-slot="{valid, errors}">
                            <program-selectbox v-model="form.program_code"
                                               :faculty_code="form.faculty_code"
                                               :validate-error="errors[0]"
                            />
                        </ValidationProvider>
                    </div>
                </b-col>
                <b-col cols="12" lg="2">
                    <div class="font-weight-medium mb-1">{{ $t('rank') }}</div>
                    <div class="mb-2">
                        <ValidationProvider name="rank" v-slot="{valid, errors}">
                            <b-select :options="rankOptions"
                                      v-model="form.rank"
                                      :class="errors[0] ? 'is-invalid':''"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </ValidationProvider>
                    </div>
                </b-col>
                <b-col cols="12" lg="1" v-if="checkPermission('doublemajorpreference_store')">
                    <b-button variant="primary" block class="mt-0 mt-lg-5" @click="storePreference">
                        <i class="ri-add-line top-plus-1 mr-1"></i> {{ $t('add') }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>

        <div class="font-weight-medium mb-1" v-if="preferenceProcessesShowStatus">{{ $t('your_preferences') }}</div>
        <b-table :empty-filtered-text="$t('no_result')"
                 :empty-text="$t('no_result')"
                 bordered
                 responsive
                 :items="preferences"
                 :fields="preferencesFields"
                 show-empty
                 class="mb-4 table-dropdown no-scrollbar border rounded"
        >
            <template #cell(status)="row">
                <span class="badge badge-warning" v-if="row.item.status.includes('waiting')">
                    {{ row.item.status_text }}
                </span>
                <span class="badge badge-success" v-else-if="row.item.status == 'approved'">
                    {{ row.item.status_text }}
                </span>
                <div v-else-if="row.item.status == 'declined'">
                    <div class="badge badge-danger">
                        {{ row.item.status_text }}
                    </div>
                    <div class="mt-1" v-if="row.item.explanation">
                        {{ row.item.explanation }}
                    </div>
                </div>
            </template>
            <template #cell(faculty_program)="row">
                {{ row.item.faculty_name }} <br>{{ row.item.program_name }}
            </template>
            <template #cell(process)="row" v-if="checkPermission('doublemajorpreference_delete')">
                <b-button @click="deletePreference(row.item.id)"
                          size="sm"
                          type="button"
                          variant="danger"
                >
                    {{ $t('delete') }}
                </b-button>
            </template>
        </b-table>
        <div class="d-flex justify-content-end" v-if="checkPermission('doublemajorpreference_save')">
            <b-button variant="primary" v-if="preferenceProcessesShowStatus && preferences.length>0" @click="savePreferences">
                {{ $t('save_preferences') }}
            </b-button>
        </div>
    </div>
</template>

<script>
// Component
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'

// Services
import DoubleMajorService from '@/services/DoubleMajorService'
import DoubleMajorPreferenceService from '@/services/DoubleMajorPreferenceService'

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

// import AddFile from "@/modules/doubleMajor/application/AddFile";

export default {
    components: {
        FacultySelectbox,
        ProgramSelectbox,

        // AddFile,

        ValidationProvider,
        ValidationObserver
    },
    props: {
        applicationData: {
            type: Object
        }
    },
    computed: {
        rankOptions() {
            let options = [1, 2, 3];
            let selected = this.preferences.length > 0 ? this.preferences.map(item => item.rank) : [];

            return options.filter(x => !selected.includes(x));
        },
        preferenceProcessesShowStatus() {
            return this.doubleMajorApplication.status == 'waiting_preference' ? true : false
        },
        preferencesFields() {
            let fields = [
                {
                    key: 'rank',
                    label: this.$t('rank'),
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                },
                {
                    key: 'faculty_program',
                    label: this.$t('faculty') + ' / ' + this.$t('program'),
                    tdClass: 'align-middle'
                },
                {
                    key: 'status',
                    label: this.$t('status'),
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                }
            ];
            if (this.preferenceProcessesShowStatus) {
                fields.push({
                    key: 'process',
                    label: '',
                    tdClass: this.checkPermission("doublemajorpreference_delete") ?'d-flex justify-content-center align-middle' : 'd-none',
                    thClass: this.checkPermission("doublemajorpreference_delete") ? 'text-center' : 'd-none',
                    sortable: false
                });
            }

            return fields;
        }
    },
    data() {
        return {
            formLoading: false,

            id: null,
            doubleMajorApplication: {},
            preferences: [],

            form: {
                faculty_code: null,
                program_code: null,
                rank: 1
            },

            documentsData:null,
            statusOfDocuments:null
        }
    },
    created() {
        this.id = this.applicationData.id;
        this.doubleMajorApplication = this.applicationData;
        this.preferences = this.applicationData.double_major_preferences
        this.statusOfDocuments = this.documentsStatus();
    },
    methods: {
        async storePreference() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                let formData = {
                    'double_major_id': this.applicationData.id,
                    ...this.form
                }
                DoubleMajorPreferenceService.store(formData)
                    .then(() => {
                        this.getDoubleMajorApplication(this.id);
                        this.form = {
                            faculty_code: null,
                            program_code: null,
                            rank: null
                        }
                    })
                    .then(() => {
                        this.$refs.formModalValidate.reset();
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        },
        deletePreference(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        DoubleMajorPreferenceService.del(id)
                            .then((response) => {
                                this.getDoubleMajorApplication(this.id);
                            })
                            .then(() => {
                                this.$refs.formModalValidate.reset();
                            })
                            .catch((e) => {
                                this.showErrors(e);
                            });
                    }
                });
        },

        savePreferences() {
            DoubleMajorPreferenceService.save()
                .then(() => {
                    this.$emit('savePreferences',this.id)
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },

        getDoubleMajorApplication(id) {
            DoubleMajorService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.doubleMajorApplication = this.applicationData;
                    this.preferences = data.double_major_preferences
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },
        documentsStatus(){
            let result = this.applicationData.documents;
            if(!result) return
            this.documentsData = Object.values(result);
            return !this.documentsData.every(document=>document.status === 'uploaded')
        }

    }
}
</script>
